@if ($loading()) {
  <div>
    <ev-loading-overlay
      [title]="'Finalizing the order...'"></ev-loading-overlay>
  </div>
} @else if ($paymentSuccessful()) {
  <div class="flex justify-center">
    <div class="container">
      <div class="heading-container">
        <div>
          <atom-icon
            class="success"
            iconId="atom:core:check_circle"></atom-icon>
        </div>
        <div class="heading">
          <span> Order successfully placed! </span>
        </div>
      </div>
      <hr />
      <div class="content">
        <div class="sub-heading">
          <span
            >We have received your order and just started working on it</span
          >
        </div>
        <div class="text">
          <span>We sent you a </span><b><span>confirmation email </span></b
          ><span>with your order details.</span>
        </div>
        <atom-button routerLink="/" secondary
          ><span>Go to homepage</span></atom-button
        >
      </div>
    </div>
  </div>
} @else {
  <div class="flex justify-center">
    <div class="container">
      <div class="heading-container">
        <div>
          <atom-icon
            class="ev-atom-error"
            iconId="atom:basf:close_circle"></atom-icon>
        </div>
        <div class="heading">
          <span> Order failed to be placed!</span>
        </div>
      </div>
      <hr />
      <div class="content">
        <div class="sub-heading">
          <span>Payment could not be processed.</span>
        </div>
        <div class="text">
          <span>Please try again later.</span>
        </div>
        <atom-button routerLink="/" secondary
          ><span>Go to homepage</span></atom-button
        >
      </div>
    </div>
  </div>
}
